<template>
  
  <div class="divSearch">
    <a-button @click="allEvent.ic7.goBatch()" v-if="dataValue.batchSendIC7.checkTrue > 0">
      批量群发推广{{ dataValue.batchSendIC7.checkTrue }}个BOM
    </a-button>
    <a-input class="company" :placeholder=" dataValue.crmTypeName +'公司名称'"
             v-model:value="dataValue.batchList.input.companyName"
             @pressEnter="allHttp.batchList()"/>
    <a-select v-model:value="dataValue.batchList.input.quotedTypeName" @change="allHttp.batchList()"
              style="width: 100px">
      <a-select-option value="0">全部</a-select-option>
      <a-select-option value="1">回复结束</a-select-option>
      <a-select-option value="2">回复中</a-select-option>
    </a-select>
    <a-button @click="allEvent.addBom.open()">上传一个BOM</a-button>
    <a-button type="primary" @click="allHttp.batchList()">查询</a-button>
    <div class="allCount">{{ dataValue.crmTypeName }}上传的BOM记录: {{ dataValue.batchList.output.allCount }} 次</div>
  </div>

  <div style="padding:15px;margin-bottom: 20px;">
    <table class="ahuiTable">
      <tr>
        <th rowspan="2">
          <check-outlined @click="allEvent.batchSendIC7.selectAll(false);" v-if="dataValue.batchSendIC7.selectAll"
                          style="color:red;"/>
          <border-outlined @click="allEvent.batchSendIC7.selectAll(true);" v-if="!dataValue.batchSendIC7.selectAll"
                           style="color: #999999;"/>
        </th>
        <th rowspan="2">批次</th>
        <th rowspan="2">{{ dataValue.crmTypeName }}公司名称</th>
        <th rowspan="2">手机</th>
        <th rowspan="2">上传数量</th>
        <th rowspan="2">总价(rmb)</th>
        <th rowspan="2">状态</th>
        <th rowspan="2">上传员工</th>
        <th rowspan="2">创建时间</th>
        <th rowspan="2">最后回复时间</th>
        <th rowspan="2">调查表</th>
        <th rowspan="2">信息</th>
        <th rowspan="2">下载</th>
        <th rowspan="2">回价</th>
        <th colspan="2" v-if="tfun.check(61)">短信推广</th>
        <th colspan="3" v-if="tfun.check(62)">邮件推广</th>
        <th rowspan="2" v-if="tfun.check(61,62)">推广</th>
      </tr>
      <tr>
        <th v-if="tfun.check(61)">已发</th>
        <th v-if="tfun.check(61)">待发</th>
        <th v-if="tfun.check(62)">已发</th>
        <th v-if="tfun.check(62)">待发</th>
        <th v-if="tfun.check(62)">待审</th>
      </tr>
      <tr v-for="aRow in dataValue.batchList.output.data"
          :style="allEvent.selectRow.selectRowColor(aRow['userBatchId'])"
          @click="allEvent.selectRow.selectRowEvent(aRow['userBatchId'])">
        <td class="logo">
          <div class="selectNode" @click="allEvent.batchSendIC7.clickRow(aRow)">
            <check-outlined v-if="aRow.checked" style="color:red;"/>
            <border-outlined v-if="!aRow.checked" style="color: #999999;"/>
          </div>
        </td>
        <td class="min">{{ aRow['userBatchId'] }}</td>
        <td>{{ aRow['crmCompanyName'] }}</td>
        <td class="min">{{ aRow['mobile'] }}</td>
        <td class="min number">{{ aRow['upCount'] }}</td>
        <td class="price">{{ tools.formatPrice(aRow['allRMBPrice'], 2) }}</td>
        <td class="min">{{ aRow['statusName'] }}</td>
        <td class="min" v-if="aRow['employeeName'] === '-'">
          <a-popconfirm title="当前订单只能认领一次，请再次确认BOM归属？" ok-text="是" cancel-text="否" @confirm="allEvent.renLing(aRow)">
            <a>认领</a>
          </a-popconfirm>
        </td>
        <td class="min" v-if="aRow['employeeName'] !== '-'">{{ aRow['employeeName'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min">{{ aRow['lastQuotedTime'] }}</td>
        <td class="min"><a @click="allEvent.dcb.goOpen(aRow)">调查表</a></td>
        <td class="min"><a @click="allEvent.companyInfo.userInfoOpen(aRow)">信息</a></td>
        <td class="min"><a @click="allEvent.batchList.downloadEvent(aRow)">下载</a></td>
        <td class="min"><a @click="allEvent.reply.goOpen(aRow)">回复</a></td>

        <td class="min number" v-if="tfun.check(61)">
          <span v-if=" aRow['smsCount'] === undefined || aRow['smsCount'] === 0" class="zero">0</span>
          <span v-if=" aRow['smsCount'] !== undefined && aRow['smsCount'] > 0" class="number">{{
              aRow['smsCount']
            }}</span>
        </td>
        <td class="min number" v-if="tfun.check(61)">
          <span v-if=" aRow['bySendCount'] === undefined || aRow['bySendCount'] === 0" class="zero">0</span>
          <span v-if=" aRow['bySendCount'] !== undefined && aRow['bySendCount'] > 0" class="number">
            {{ aRow['bySendCount'] }}
            <a-popconfirm title="确认要删除所有未发送的短信记录？" ok-text="是" cancel-text="否" @confirm="allHttp.ic7.delPlan(aRow)">
              <label class="goDel" style="cursor: pointer;">取消</label>
            </a-popconfirm>
          </span>
        </td>

        <td class="min number" v-if="tfun.check(62)">
          <span v-if=" aRow['sendSize'] === undefined || aRow['sendSize'] === 0" class="zero">0</span>
          <a v-if=" aRow['sendSize'] !== undefined && aRow['sendSize'] > 0" class="number"
             @click="allEvent.mailHistory.goOpen(aRow,1,'查看已发送记录')">{{ aRow['sendSize'] }}</a>
        </td>

        <td class="min number" v-if="tfun.check(62)">
          <span v-if=" aRow['planSize'] === undefined || aRow['planSize'] === 0" class="zero">0</span>
          <span v-if=" aRow['planSize'] !== undefined && aRow['planSize'] > 0" class="number">
            <a class="aShowByMail" @click="allEvent.mailHistory.goOpen(aRow,0, '查看待发送记录')">{{ aRow['planSize'] }}</a>
            <a-popconfirm title="确认要删除所有未发送的记录？" ok-text="是" cancel-text="否" @confirm="allHttp.ic7mail.delPlan(aRow,0)">
              <a class="goDel">取消</a>
            </a-popconfirm>
          </span>
        </td>
        <td class="min number" v-if="tfun.check(62)">
          <span v-if=" aRow['examineSize'] === undefined || aRow['examineSize'] === 0" class="zero">0</span>
          <span v-if=" aRow['examineSize'] !== undefined && aRow['examineSize'] > 0" class="number">
            <a class="aShowByMail" @click="allEvent.mailHistory.goOpen(aRow,2, '查看待审批记录')">{{ aRow['examineSize'] }}</a>
            <a-popconfirm title="确认要删除待审批的记录？" ok-text="是" cancel-text="否" @confirm="allHttp.ic7mail.delPlan(aRow,2)">
              <a class="goDel">取消</a>
            </a-popconfirm>
          </span>
        </td>

        <td class="min" v-if="tfun.check(61,62)">
          <a @click="allEvent.ic7.goOpen(aRow);" v-if="tfun.check(61)">短信</a>
          <a style="margin-left: 4px;" @click="allEvent.mail.goOpen(aRow);" v-if="tfun.check(62)">邮件</a>
        </td>
      </tr>
      <tr style="background-color: #ffffff;">
        <td colspan="23" class="pages">
          <APages :number_of_page="dataValue.batchList.input.numberOfPage"
                  :all_count="dataValue.batchList.output.allCount"
                  :this_page="dataValue.batchList.input.page"
                  :all_page="dataValue.batchList.output.allPage"
                  @toPage="allEvent.batchList.toPage"
          />
        </td>
      </tr>
    </table>

  </div>


  <a-drawer
      class="aDrawer"
      :title="'查看'+ dataValue.crmTypeName +'公司的信息'"
      width="300"
      :visible="dataValue.companyInfo.visible"

      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.companyInfo.userInfoClose"
  >
    <userInfo :company_id="dataValue.companyInfo.crmCompanyId"/>
  </a-drawer>


  <a-drawer
      :title="dataValue.crmTypeName +'BOM通过IC7批量询价报价'"
      width="500"
      :visible="dataValue.toIC7.visible"

      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.ic7.goClose"
  >

    <ic7Batch :row_objs="dataValue.toIC7.props.rowObjs" :index_id_name="dataValue.toIC7.props.indexIdName"
              :crm_type_id="dataValue.toIC7.props.crmTypeId" :company_name="dataValue.toIC7.props.companyName"/>
  </a-drawer>


  <a-drawer
      :title="dataValue.mailHistory.title"
      width="600"
      :visible="dataValue.mailHistory.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.mailHistory.goMailHistoryClose"
  >

    <mailHistory :factory_stockId="dataValue.mailHistory.props.factoryStockId"
                 :stock_batch_id="dataValue.mailHistory.props.stockBatchId"
                 :user_batch_id="dataValue.mailHistory.props.userBatchId"
                 :is_send="dataValue.mailHistory.props.isSend"
                 @goMailClose="allEvent.mailHistory.goMailHistoryClose"/>
  </a-drawer>

  <a-drawer
      :title="dataValue.crmTypeName +'邮件推广'"
      width="950"
      :visible="dataValue.mail.visible"

      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.mail.goClose"
  >

    <mailBatch :row_obj="dataValue.mail.props.rowObj" :crm_type_id="dataValue.mail.props.crmTypeId"
               @goClose="allEvent.mail.goClose2"/>
  </a-drawer>


  <a-drawer
      :title="dataValue.reply.title"
      width="1400"
      :visible="dataValue.reply.visible"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.reply.goClose"
  >
    <reply :row_obj="dataValue.reply.rowObj" @replyGoClose="allEvent.reply.goClose"
           @replyGoDelClose="allEvent.reply.goDelClose"
           :crm_type_id="dataValue.crmTypeId" :crm_type_name="dataValue.crmTypeName"/>
  </a-drawer>

  <a-drawer
      title="查看Bom的调查表"
      width="380"
      :visible="dataValue.dcb.visible"

      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.dcb.goClose"
  >

    <dcbPage :row_obj="dataValue.dcb.props.rowObj" @goClose="allEvent.dcb.goClose"/>
  </a-drawer>


  <a-drawer
      title="上传一个BOM"
      width="780"
      :visible="dataValue.addBom.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="left"
      @close="allEvent.addBom.goClose" >
    <addBomPage :crm_type_id="dataValue.addBom.crmTypeId" @goClose="allEvent.addBom.goClose2" />
  </a-drawer>

</template>

<script>

import {reactive} from "vue";
import {message} from "ant-design-vue";
import httpRequest from "@/utils/axios";
import ahuiTable from "@/components/table";
import detailPage from "@/view/order/stock.one.detail"
import userInfo from "@/view/company/user_info"
import APages from "@/tools/pages"
import tools from "@/tools/small_methods"
import ic7Batch from "@/view/order/ic7_batch"
import mailBatch from "@/view/order/mail_batch"
import mailHistory from "@/view/order/mail_history";
import reply from "@/view/order/user.need.reply"
import {useRoute} from "vue-router";
import dcbPage from "@/view/order/user.need.dcb"
import addBomPage from "@/view/order/add_bom"
import store from "@/store";
import tfun from "@/tools/tfun";


export default {
  name : "factory.sport",
  components : { ahuiTable, detailPage, userInfo, APages, ic7Batch, mailBatch, reply, mailHistory, dcbPage, addBomPage, store },
  emits : [ 'setMenuKey' ],
  setup(props, { emit }) {


    let dataValue = reactive({

      addBom : {
        visible : false,
        crmTypeId : 0
      },

      crmTypeId : 0,
      crmTypeName : "",
      selectRowId : 0,

      dcb : {
        visible : false,
        props : {
          rowObj : null
        }
      },
      reply : {
        title : "",
        rowObj : null,
        visible : false,
      },

      batchSendIC7 : {
        checkTrue : 0,
        checkFalse : 0,
        selectAll : false
      },

      batchList : {
        input : {
          page : 1,
          numberOfPage : 30,
          companyName : "",
          userBatchId : 0,
          quotedType : 0,
          quotedTypeName : "0",
          crmTypeId : 0,
        },
        output : {
          allPage : 0,
          allCount : 0,
          data : []
        }
      },

      companyInfo : {
        crmCompanyId : 0,
        visible : false,
      },

      mail : {
        "visible" : false,
        props : {
          rowObjs : [],
          indexIdName : "",
          crmTypeId : 0,
          companyName : ""
        }
      },

      mailHistory : {
        title : "",
        visible : false,
        props : {
          factoryStockId : 0,
          stockBatchId : 0,
          userBatchId : 0,
          isSend : 1
        }
      },

      toIC7 : {
        "visible" : false,
        props : {
          rowObj : {},
          indexIdName : "",
          crmTypeId : 0,
          companyName : ""
        }
      }
    })


    // 区分是工厂销售还是贸易商
    switch (useRoute().params.type.toLowerCase()) {
      case "factory.spot":
        emit('setMenuKey', 'dj.gcxhbj');
        dataValue.crmTypeId = 3;
        dataValue.crmTypeName = "工厂销售";
        break;
      case "merchant": // 贸易商
        emit('setMenuKey', 'dj.mysbj');
        dataValue.crmTypeId = 2;
        dataValue.crmTypeName = "贸易商";
        break;
      default:
        return;
    }


    let allHttp = {

      batchList : () => {


        dataValue.batchList.input.quotedType = parseInt(dataValue.batchList.input.quotedTypeName);
        dataValue.batchList.input.crmTypeId = dataValue.crmTypeId;

        httpRequest.post('/admin/user.need/inquiry.batch', dataValue.batchList.input)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.batchList.output.allPage = res.data.allPage;
                dataValue.batchList.output.allCount = res.data.allCount;
                dataValue.batchList.output.data = res.data.list;

                // 获得已发信息和未发信息的记录值
                dataValue.batchList.output.data.forEach((row) => {
                  allHttp.ic7.RowCount(row);
                  allHttp.ic7.BySendCount(row);
                  allHttp.ic7mail.getCount(row);
                });
              }
            })
      },

      getOne : (rowObj) => { // 更新其中一行

        let inputOne = {
          page : 1,
          numberOfPage : 30,
          userBatchId : rowObj['userBatchId']
        }

        httpRequest.post('/admin/user.need/inquiry.batch', inputOne)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                if (res.data.list.length > 0) {
                  rowObj['quotedCount'] = res.data.list[0]['quotedCount'];
                  rowObj['noQuotedCount'] = res.data.list[0]['noQuotedCount'];
                  rowObj['statusName'] = res.data.list[0]['statusName'];
                }
              }
            })
      },

      ic7mail : {

        getCount : (rowObj) => {

          let inputOne = {
            factoryStockId : 0,
            stockBatchId : 0,
            userBatchId : rowObj['userBatchId']
          }

          httpRequest.post('/admin/ic7.mail/send.count', inputOne)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                } else {
                  rowObj['sendSize'] = res.data['sendSize'];
                  rowObj['planSize'] = res.data['planSize'];
                  rowObj['examineSize'] = res.data['examineSize'];
                }
              })
        },

        delPlan : (rowObj, type) => {
          let inputOne = {
            factoryStockId : 0,
            stockBatchId : 0,
            userBatchId : rowObj['userBatchId'],
            typeId : type
          }

          httpRequest.post('/admin/ic7.mail/del.plan', inputOne)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                } else {
                  allHttp.ic7mail.getCount(rowObj);
                }
              })
        }

      },

      ic7 : {

        RowCount : (row) => {
          httpRequest.post('/admin/ic7/get.send.sms', {
            "factoryStockId" : 0,
            "stockBatchId" : 0,
            "userBatchId" : row['userBatchId'],
            "onlyCount" : 1,
            "crmTypes" : [ "2", "3" ]
          }).then((res) => {
            if (res.code === 200) {
              row['smsCount'] = res.data.allCount;
            }
          })
        },

        BySendCount : (row) => {
          httpRequest.post('/admin/ic7/plan.count', {
            "factoryStockId" : 0,
            "stockBatchId" : 0,
            "userBatchId" : row['userBatchId']
          }).then((res) => {
            if (res.code === 200) {
              row['bySendCount'] = res.data.allCount;
            }
          })
        },

        delPlan : (row) => {
          httpRequest.post('/admin/ic7/del.plan', {
            "factoryStockId" : 0,
            "stockBatchId" : 0,
            "userBatchId" : row['userBatchId']
          }).then((res) => {
            if (res.code === 200) {
              allHttp.ic7.BySendCount(row);
            }
          })
        }
      }

    }

    allHttp.batchList();


    let allEvent = {


      renLing : (row)=>{

        httpRequest.post('/admin/user.need/set.employee', { "userBatchId":row.userBatchId })
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                row['employeeName'] = res.data['employeeName'];
              }
            })

      },

      addBom : {

        open : ()=>{
          dataValue.addBom.crmTypeId = dataValue.crmTypeId;
          dataValue.addBom.visible = true;
        },

        goClose : ()=>{
          dataValue.addBom.visible = false;
        },

        goClose2 : ()=>{
          allHttp.batchList();
          dataValue.addBom.visible = false;
        }

      },

      dcb : {
        goOpen : (row) => {
          dataValue.dcb.props.rowObj = row;
          dataValue.dcb.visible = true;
        },

        goClose : () => {
          dataValue.dcb.visible = false;
        }

      },

      batchSendIC7 : {

        calculateCount : () => {
          dataValue.batchSendIC7.checkFalse = 0;
          dataValue.batchSendIC7.checkTrue = 0;
          dataValue.batchList.output.data.forEach((node) => {
            if (node['checked']) dataValue.batchSendIC7.checkTrue++;
            else dataValue.batchSendIC7.checkFalse++;
          });

          dataValue.batchSendIC7.selectAll = dataValue.batchSendIC7.checkTrue > 0 && dataValue.batchSendIC7.checkFalse === 0;
        },

        selectAll : (checkType) => {
          dataValue.batchList.output.data.forEach((row) => {
            // console.log(row)
            row['checked'] = checkType;
          });
          allEvent.batchSendIC7.calculateCount();
        },

        clickRow : (row) => {
          row['checked'] = !row['checked'];
          allEvent.batchSendIC7.calculateCount();
        }

      },


      batchList : {

        downloadEvent : (row) => {

          message.destroy();
          message.loading({ content : '正在下载文件(编号:' + row['userBatchId'] + ')，请稍后 ...', duration : 0 });

          let dataInput = {
            crmTypeId : dataValue.crmTypeId,
            page : 1,
            numberOfPage : 999999,
            companyName : "",
            userBatchId : parseInt(row['userBatchId']),
            userNodeId : 0,
            manufacturerName : "",
            modelName : "",
            isDownload : 1
          }
          httpRequest.postDownload('/admin/user.need/inquiry.list', dataInput)
              .then(() => {
                message.destroy();
              })
        },

        toPage : (pageNumber) => {
          dataValue.batchList.input.page = pageNumber;
          dataValue.batchSendIC7.selectAll = false;
          dataValue.batchSendIC7.checkTrue = 0;
          dataValue.batchSendIC7.checkFalse = 0;
          allHttp.batchList();
        }

      },

      companyInfo : {

        userInfoOpen : (row) => {
          dataValue.companyInfo.crmCompanyId = row['crmCompanyId'];
          dataValue.companyInfo.visible = true;
        },
        userInfoClose : () => {
          dataValue.companyInfo.visible = false;
        }

      },

      reply : {

        goOpen : (row) => {
          dataValue.reply.rowObj = row;
          dataValue.reply.visible = true;
          dataValue.reply.title = "回复来自" + dataValue.crmTypeName + ": " + row['crmCompanyName'] + " 的BOM信息"
        },

        goClose : (rowObj) => {
          dataValue.reply.visible = false;
          allHttp.getOne(rowObj);
        },

        goDelClose : () => {
          dataValue.reply.visible = false;
          allHttp.batchList();
        }

      },


      mail : {

        goOpen : (row) => {
          dataValue.mail.props.rowObj = row;
          dataValue.mail.props.crmTypeId = dataValue.crmTypeId;
          dataValue.mail.visible = true;
        },

        goClose : () => {
          dataValue.mail.visible = false;
        },

        goClose2 : () => {

          allHttp.ic7mail.getCount(dataValue.mail.props.rowObj);
          dataValue.mail.visible = false;
        }

      },

      mailHistory : {
        goOpen : (row, isSend, title) => {
          dataValue.mailHistory.props.userBatchId = row['userBatchId'];
          dataValue.mailHistory.props.isSend = isSend;
          dataValue.mailHistory.title = title;
          dataValue.mailHistory.visible = true;
        },

        goMailHistoryClose : () => {
          dataValue.mailHistory.visible = false;
        }

      },


      ic7 : {

        goBatch : () => {

          dataValue.toIC7.props.rowObjs = [];
          let beginCompanyName = "";

          dataValue.batchList.output.data.forEach((node) => {
            if (node['checked']) {
              if (beginCompanyName === "") beginCompanyName = node["crmCompanyName"];
              dataValue.toIC7.props.rowObjs.push(node);
            }


          });

          dataValue.toIC7.props.indexIdName = "userBatchId";
          dataValue.toIC7.props.crmTypeId = dataValue.crmTypeId;
          dataValue.toIC7.props.companyName = "来自 “" + beginCompanyName + "” 等" + dataValue.batchSendIC7.checkTrue + "家公司";
          dataValue.toIC7.visible = true;

        },

        goOpen : (row) => {
          dataValue.toIC7.props.rowObjs = [];
          dataValue.toIC7.props.rowObjs.push(row);
          dataValue.toIC7.props.indexIdName = "userBatchId";
          dataValue.toIC7.props.crmTypeId = dataValue.crmTypeId;
          dataValue.toIC7.props.companyName = row['crmCompanyName'];
          dataValue.toIC7.visible = true;
        },

        goClose : () => {

          dataValue.toIC7.props.rowObjs.forEach((row) => {
            allHttp.ic7.RowCount(row);
            allHttp.ic7.BySendCount(row);
          });
          dataValue.toIC7.visible = false;
        }

      },

      selectRow : { // 选择其中一行
        selectRowEvent : (rowId) => {
          dataValue.selectRow = rowId;
        },
        selectRowColor : (rowId) => {
          return dataValue.selectRow === rowId ? "background-color:#d8ffd8;" : "";
        }
      }


    }


    return {
      dataValue,
      allHttp,
      tools,
      tfun,
      allEvent
    }
  }
}
</script>

<style scoped>
.divSearch { padding: 15px 15px 0 15px; }
.divSearch * { margin-right: 20px; }
.divSearch .company { width: 350px; }
.allCount { float: right; line-height: 36px; }

.aShowByMail { margin: 0 3px 0 3px; }
.aShowByMail:hover { color: red; }
.full { cursor: pointer; margin: 0; }
.full:hover { color: red; }
.mail2 { float: left; margin-right: 4px; cursor: pointer; }
.mail2:hover { color: red; }
</style>


