<template>
  <view class="t" v-if="!dataValue.showDCB">
    此BOM无调查表
  </view>
  <view class="t" v-if="dataValue.showDCB">
    <template v-for="aDCB in dataValue.dcb">
      <view class="title">{{ aDCB['index'] }}、{{ aDCB['question'] }}</view>
      <view class="vInfo">{{ aDCB['checkValue'] }}</view>
    </template>
  </view>
</template>

<script>

import {reactive} from "vue";

export default {
  name : "user.need.dcb",
  props : [ 'row_obj' ],
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      showDCB : props.row_obj['dcb'] !== null,
      dcb : props.row_obj['dcb']
    })

    //console.log(dataValue.dcb)

    return {
      dataValue
    }
  }
}
</script>

<style scoped>
.t { padding-bottom: 30px; float: left;}
.title { clear: both; float: left; margin-bottom: 10px; font-weight: bold; }
.vInfo { clear: both; float: left; margin-left: 30px; margin-bottom: 15px; color: #444444; }
.vInfo > view { clear: both; float: left; margin-right: 30px; }
</style>