<template>
  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在处理数据 ..."></a-spin>

  <a-popconfirm class="delButton" v-if="dataValue.loading === true" title="确认要删除当前BOM？" ok-text="是" cancel-text="否" @confirm="allHttp.delBom">
    <a-button style="float: right;margin-right:20px;">删除BOM</a-button>
  </a-popconfirm>
  <div v-if="dataValue.loading === false">
    <div style="position:absolute;top:11px;right:25px;width:700px;">
      <div style="float:left;">
        <span>统一设置回复价格是："参考价格的"</span>
        <a-input style="width: 50px;margin: 0 10px 0 10px;text-align: center;" v-model:value="dataValue.setPrice"/>
        <span>倍</span>
        <a-button style="margin-left:10px;" @click="allEvent.setReplayPrice">设置</a-button>
      </div>
      <a-button type="primary" style="float: right;" @click="allHttp.saveReplayPrice">保存回复价格</a-button>
      <a-popconfirm title="确认要删除当前BOM？" ok-text="是" cancel-text="否" @confirm="allHttp.delBom">
        <a-button style="float: right;margin-right:20px;">删除BOM</a-button>
      </a-popconfirm>
    </div>
    <div>

      <div class="tj">
        <div class="n">
          <div>总条目数：</div>
          <div>{{dataValue.detail.outputData.analyse['zsm']}}</div>
        </div>
        <div class="n">
          <div>冷门型号：</div>
          <div>{{dataValue.detail.outputData.analyse['lmxhsm']}}</div>
        </div>
        <div class="n">
          <div>热门型号：</div>
          <div>{{dataValue.detail.outputData.analyse['rmxhsm']}}</div>
        </div>
        <div class="p">
          <div>金额最大：</div>
          <div>{{dataValue.detail.outputData.analyse['jgzdxhbjName']}}</div>
          <div>{{dataValue.detail.outputData.analyse['jgzdxhbj']}}</div>
        </div>
        <div class="p">
          <div>数量最多：</div>
          <div>{{dataValue.detail.outputData.analyse['slzdxhbjName']}}</div>
          <div>{{dataValue.detail.outputData.analyse['slzdxhbj']}}</div>
        </div>
      </div>
      <div class="tj">
        <div class="n">
          <div>估价记录：</div>
          <div>{{dataValue.detail.outputData.analyse['ybjsm']}}</div>
        </div>
        <div class="n">
          <div>通用型号：</div>
          <div>{{dataValue.detail.outputData.analyse['tyxhsm']}}</div>
        </div>
        <div class="n">
          <div>网红型号：</div>
          <div>{{dataValue.detail.outputData.analyse['whxhsm']}}</div>
        </div>
        <div class="p">
          <div>交期最长：</div>
          <div>{{dataValue.detail.outputData.analyse['jqzcxhbjName']}}</div>
          <div>{{dataValue.detail.outputData.analyse['jqzcxhbj']}}</div>
        </div>
        <div class="p">
          <div>总估价：</div>
          <div></div>
          <div>{{dataValue.detail.outputData.analyse['zbj']}} {{ dataValue.moneyTypeName }}</div>
        </div>
      </div>
      <table class="ahuiTable">
        <tr>
          <th>编号</th>
          <th>选中</th>
          <th>型号</th>
          <th>类型</th>
          <th>类星</th>
          <th>厂商</th>
          <th>批号</th>
          <th>数量</th>
          <th>人民币估价</th>
          <th>估价时间</th>
          <th>回复价格</th>
          <th>回复时间</th>
          <th>创建时间</th>
          <th>分析</th>
        </tr>
        <tr v-for="aRow in dataValue.detail.outputData.list"
            :style="allEvent.selectRow.selectRowColor(aRow['userNodeId'])"
            @click="allEvent.selectRow.selectRowEvent(aRow['userNodeId'])">
          <td class="min">{{ aRow['userNodeId'] }}</td>
          <td class="gou">{{ aRow['checked'] === true ? '√' : '' }}</td>
          <td>{{ aRow['modelName'] }}</td>
          <td class="min">{{ aRow['modelType'] }}</td>
          <td class="min">{{ aRow['modelStar'] }}</td>
          <td class="min">{{ aRow['manufacturerName'] }}</td>
          <td class="min">{{ aRow['batchName'] }}</td>
          <td class="number">{{ aRow['number'] }}</td>

          <td class="price" style="width: 120px;">{{ aRow['assessPrice'] }}</td>
          <td class="min" style="width: 120px;">{{ aRow['assessPriceDay'] }}</td>

          <td style="padding: 0; width: 120px;">
            <a-input class="price" style="font-size:12px;" v-model:value="aRow['replyPrice']"/>
          </td>
          <td class="min">{{ aRow['replyTime'] }}</td>
          <td class="min">{{ aRow['createTime'] }}</td>
          <td class="min"><a class="aBQSet" @click="allEvent.bi.open(aRow)">分析</a></td>
        </tr>
      </table>

    </div>
  </div>
  <a-drawer
      :title="dataValue.bi.modelName +'的分析信息'"
      width="1150"
      :visible="dataValue.bi.show"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.bi.close"
  >
    <biModel :model_name="dataValue.bi.modelName"  />
  </a-drawer>
</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import tools from "@/tools/small_methods"
import biModel from "@/view/bi/bi_model"


export default {
  name : "factory.sport.reply",
  props : [ 'row_obj', 'crm_type_id', 'crm_type_name' ],
  emits : [ 'replyGoClose', 'replyGoDelClose' ],
  components : { biModel },
  setup(props, { emit }) {

    let dataValue = reactive({
      bi:{
        show: false,
        modelName : ""
      },
      moneyTypeName : "RMB",
      rowObj : props.row_obj,
      userBatchId : props.row_obj['userBatchId'],
      loading: false,
      setPrice : 1.0,
      detail : {
        inputData : {
          crmTypeId: props.crm_type_id,
          page : 1,
          numberOfPage : 999999,
          companyName : "",
          userBatchId : parseInt(props.row_obj['userBatchId']),
          userNodeId : 0,
          manufacturerName : "",
          modelName : "",
          isDownload : 0
        },
        outputData : {
          allCount : 0,
          allPage : 0,
          list : [],
          analyse : null
        }
      }
    });

    let allHttp = {

      delBom :()=>{

        httpRequest.post('/admin/user.need/inquiry.del', {"userBatchId": dataValue.userBatchId})
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                emit('replyGoDelClose');
              }
            })
      },

      detail : () => {

        dataValue.loading = true;

        httpRequest.post('/admin/user.need/inquiry.list', dataValue.detail.inputData)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.detail.outputData.allPage = res.data.allPage;
                dataValue.detail.outputData.allCount = res.data.allCount;
                dataValue.detail.outputData.list = res.data.list;
                dataValue.detail.outputData.analyse = res.data.analyse;

                // dataValue.detail.outputData.list.forEach((row) => {
                //   allHttp.priceA(row);
                // });

                dataValue.loading = false;
              }
            })
      },

      // priceA : (row) => {
      //
      //   if( row['modelName'] === "" ) return;
      //
      //   let inputData = {
      //     modelName : row['modelName'],
      //     requestType : 2,
      //     batchNumber : row['batchNumber'],
      //     haveCache : 0
      //   }
      //
      //   httpRequest.post('/modelobj/price.a', inputData)
      //       .then((res) => {
      //         if (res.code !== 200) {
      //           message.error(res.message);
      //         } else {
      //           row['priceARmbPrice'] = res.data['rmbPrice'];
      //           row['priceAMonthData'] = res.data['monthData'];
      //         }
      //       })
      //
      // },

      saveReplayPrice : () => {

        dataValue.loading = true;

        let allSize = dataValue.detail.outputData.list.length;

        let testClose = () => {
          if (--allSize <= 0){
            dataValue.loading = false;
            emit('replyGoClose', dataValue.rowObj);
          }
        }

        dataValue.detail.outputData.list.forEach((row) => {

          let inputData = {
            "userNodeId" : parseInt(row['userNodeId']),
            "replyPrice" : row['replyPrice'],
            "replyMoneyTypeName" : "人民币"
          }

          httpRequest.post('/admin/user.need/inquiry.reply', inputData)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                }
                testClose();
              })

        });
      }

    }

    allHttp.detail();


    let allEvent = {
      bi : {
        open : (row)=>{
          dataValue.bi.modelName = row['modelName'];
          dataValue.bi.show = true;
        },
        close: ()=>{
          dataValue.bi.show = false;
        }
      },

      selectRow : { // 选择其中一行
        selectRowEvent : (rowId) => {
          dataValue.selectRow = rowId;
        },
        selectRowColor : (rowId) => {
          return dataValue.selectRow === rowId ? "background-color:#d8ffd8;" : "";
        }
      },

      setReplayPrice : () => {

        dataValue.detail.outputData.list.forEach((row) => {
          row['replyPrice'] = (parseFloat(row['priceARmbPrice']) * dataValue.setPrice).toFixed(4);
        });
      }

    }


    return {
      dataValue,
      allEvent,
      tools,
      allHttp
    }
  }
}
</script>

<style scoped>
.delButton{ position:fixed; right: 10px; top:10px; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.tj {width: 100%; clear: both; margin-bottom: 10px;float: left; line-height: 50px; }
.tj > div {border: 1px solid #cccccc;float: left; margin: 0 1% 0 1%; border-radius: 3px; background-color: #ffffff; }
.tj .n{ width: 10%;  }
.tj .n > div:nth-child(1) {float: left; margin: 0 1px 0 20px;font-weight: bold; color:#1a5f91;}
.tj .n > div:nth-child(2) {color:#1a5f91;;}
.tj .p{ width: 29.8%;  }
.tj .p > div:nth-child(1) {float: left; margin: 0 1px 0 20px;font-weight: bold; color:#1a5f91;}
.tj .p > div:nth-child(2) {color:#555555; float: left; width: 150px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap; }
.tj .p > div:nth-child(3) {color:#225DDC;; float: right; margin-right: 15px; }
.gou{ text-align:center; font-weight:bold; color:red; font-family:"Songti SC"; width:30px; }
</style>